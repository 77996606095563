import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "~/classes/models/base.model";

// Announcement have:
// - id: string
// - title: string
// - content: any
// - createdAtTimestamp: number;
// - updatedAtTimestamp: number;

export type Announcements = Announcement[];

type AnnouncementConstructorParams = {
  id?: string;
  title: string;
  content: any;
  createdAtTimestamp: number;
  updatedAtTimestamp: number;
  state: "draft" | "published";
  displayOrder: number;
};

export class Announcement extends BaseModel {
  id?: string;
  title: string;
  content: any;
  createdAtTimestamp: number;
  updatedAtTimestamp: number;
  state: "draft" | "published";
  displayOrder: number;

  constructor(params: AnnouncementConstructorParams) {
    super();
    this.id = params.id;
    this.title = params.title;
    this.content = params.content;
    this.createdAtTimestamp = params.createdAtTimestamp;
    this.updatedAtTimestamp = params.updatedAtTimestamp;
    this.state = params.state;
    this.displayOrder = params.displayOrder;
  }

  static fromMap(data: any): Announcement {
    return new Announcement(data);
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/announcements`,
      path: `/announcements/${this.id}`,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.updatedAtTimestamp = Date.now();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    const db = DBFactory.createDatabase();
    await db.delete(this.databaseConfig);
  }
}
